<template>
<div>

    <!-- List Cart -->
    <div :key="index" v-for="(product, index) in products" :id="index" :style="{animation: 'fadeIn ' +  (index * 0.6) + 's'}">
        <b-card class="order-product-view" no-body>
            <b-row>

                <b-col md="2">
                    <b-link>
                        <b-img class="product-image" :src="product.product_image" :alt="`${product.name}-${product.id}`" />
                    </b-link>
                </b-col>

                <b-col md="3" class="product-center-things">
                    {{ product.product_name }}
                </b-col>

                <b-col md="2" class="product-center-things">
                    <b-form-input id="price_pcs" placeholder="Price" v-model="product.price_pcs" @keyup="calculatePrice(index, 'total')" />
                </b-col>

                <b-col md="2" class="product-center-things">
                    <b-form-spinbutton v-model="product.qty" size="sm" class="ml-75" @change="calculatePrice(index)" inline />
                </b-col>

                <b-col md="1" class="product-center-things">
                    <b-form-input id="price_pcs" placeholder="Price" type="number" v-model="product.full_price" :value="12" @keyup="calculatePrice(index, 'pcs')" />
                </b-col>

                <b-col md="1" class="product-center-things">
                    <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" @click="deleteProductFromCart(index, product.id)" variant="flat-danger" size="lg" class="btn-icon rounded-circle">
                        <feather-icon icon="XCircleIcon" />
                    </b-button>
                </b-col>

            </b-row>
        </b-card>
    </div>
    <!-- End of list -->
    <div v-for="(added, indexs) in addedProduct" v-bind:data="added" v-bind:key="indexs" :style="{animation: 'slideInDown 0.5s'}">
        <b-card class="order-product-add" no-body>

            <b-row>

                <b-col md="5" class="product-center-things">
                    <v-select id="country" :options="productsList" v-model="added.product" :selectable="option => ! option.value.includes('select_value')" label="name" />
                </b-col>

                <b-col md="2" class="product-center-things">
                    <b-form-input id="price_pcs" placeholder="Price" v-model="added.price_pcs" @keyup="calculateNewPrice(indexs, 'total')" />
                </b-col>

                <b-col md="2" class="product-center-things">
                    <b-form-spinbutton v-model="added.qty" size="sm" class="ml-75" @change="calculateNewPrice(indexs)" inline />
                </b-col>

                <b-col md="1" class="product-center-things">
                    <b-form-input id="price_pcs" placeholder="Price" type="number" v-model="added.full_price" :value="12" @keyup="calculateNewPrice(indexs, 'pcs')" />
                </b-col>

                <b-col md="1" class="product-center-things">
                    <b-button @click="deleteNewProductFromCart(indexs)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-danger" size="lg" class="btn-icon rounded-circle">
                        <feather-icon icon="XCircleIcon" />
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>

    <b-button @click="addProductToDatabase()" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" size="lg" class="btn-icon rounded-circle">
        Do it
    </b-button>

    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" block variant="outline-success" pill @click="addProductToCart">Add Product To Cart</b-button>

</div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BLink,
    BRow,
    BCol,
    BImg,
    BButton,
    BBadge,
    BFormInput,
    BFormSpinbutton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
    mapGetters
} from "vuex";
import Ripple from 'vue-ripple-directive'
import Api from "@/http/Api";

export default {
    data() {
        return {
            addProduct: false,
            addedProduct: []
        }
    },
    components: {
        BCard,
        BCardBody,
        BLink,
        vSelect,
        BFormInput,
        BRow,
        BCol,
        BImg,
        BButton,
        BBadge,
        BFormSpinbutton,
    },
    directives: {
        Ripple,
    },
    name: "Products",
    props: ['products'],

    mounted() {
        this.$store.dispatch("GET_PRODUCTS");
    },

    computed: {
        ...mapGetters(["productsList"]),
    },

    watch: {

    },
    methods: {

        calculatePrice: function (index, key) {
            if (key === 'total') {
                var mathTotal = this.products[index].price_pcs * this.products[index].qty;

                this.products[index].full_price = mathTotal.toFixed(2);
            } else if (key === 'pcs') {
                const mathPCS = this.products[index].full_price / this.products[index].qty;

                this.products[index].price_pcs = mathPCS.toFixed(2);
            } else {
                const mathTotal = this.products[index].price_pcs * this.products[index].qty;

                this.products[index].full_price = mathTotal.toFixed(2);
            }

        },

        calculateNewPrice: function (index, key) {
            if (key === 'total') {
                var mathTotal = this.addedProduct[index].price_pcs * this.addedProduct[index].qty;

                this.addedProduct[index].full_price = mathTotal.toFixed(2);
            } else if (key === 'pcs') {
                const mathPCS = this.addedProduct[index].full_price / this.addedProduct[index].qty;

                this.addedProduct[index].price_pcs = mathPCS.toFixed(2);
            } else {
                const mathTotal = this.addedProduct[index].price_pcs * this.addedProduct[index].qty;

                this.addedProduct[index].full_price = mathTotal.toFixed(2);
            }
        },

        addProductToDatabase() {
            return new Promise((resolve, reject) => {
                Api().post("/order/" + this.$route.params.id + '/addProduct', this.$data.addedProduct).then(response => {
                        this.$swal({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Product added!',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        error.response.data.message.map((value, key) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Error',
                                    text: value,
                                    icon: 'XIcon',
                                    variant: 'danger',
                                },
                            })
                        });
                    });
                resolve(true)
            })
        },

        deleteProductFromCart: function (index, id) {
            return new Promise((resolve, reject) => {
                Api().delete('/order/' + this.$route.params.id + '/product/' + id).then(response => {
                        this.products.splice(index, 1);
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Product deleted!',
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error',
                                text: 'Something bad happened, please report to Administrator!',
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        })
                    });
                resolve(true)
            })
        },

        addProductToCart: function () {
            this.addedProduct.push({
                qty: 1,
                price_pcs: '',
                full_price: ''
            });
        },

        deleteNewProductFromCart: function (index) {
            this.addedProduct.splice(index, 1);
        },
    }
}
</script>

<style lang="scss">
.order-product-view {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background: rgb(255, 255, 255);
    //border: 1px solid #000;
}

.order-product-add {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background: rgb(255, 255, 255);
    margin-top: 30px;
    //border: 1px solid #000;
}

.product-image {
    max-width: 100px;
    position: relative;
    margin-left: 10px;
}

.product-center-things {
    position: relative;
    margin: auto;
}
</style>
