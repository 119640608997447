<template>
<div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="order === null">
        <h4 class="alert-heading">
            Error fetching order data, Administrator is notified!
        </h4>

    </b-alert>
 <b-tabs content-class="mt-1">
    <!-- This tabs content will always be mounted -->
    <b-tab title="Order Details">
      
        <b-row>
        <b-col md="12">
            <b-card v-if="order" no-body>

                <b-card-body>
                    <div class="status" v-bind:style="{ color: order.status_font_color, 'background-color': order.status_back_color }">
                        {{ order.status }}
                    </div>

                    <div class="text-center">
                        <h4>Order #{{order.id}} </h4>
                        <b-card-text>{{order.name}}</b-card-text>
                    </div>

                </b-card-body>
            </b-card>
        </b-col>

        <b-col md="4">
            <b-card title="Delivery Info">
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Client" label-for="h-first-name" label-cols-md="4">
                            <b-form-input id="h-first-name" placeholder="Client Name" :value=order.name />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="eMail" label-for="email" label-cols-md="4">
                            <b-form-input id="email" type="email" placeholder="eMail" :value=order.email />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Phone" label-for="phone" label-cols-md="4">
                            <b-form-input id="email" type="number" placeholder="Phone" :value=order.phone />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="City" label-for="city" label-cols-md="4">
                            <b-form-input id="city" type="text" :value=order.city />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Address" label-for="h-number" label-cols-md="4">
                            <b-form-input id="h-number" type="text" :value=order.address />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Province" label-for="province" label-cols-md="4">
                            <b-form-input id="province" type="text" :value=order.province />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="ZIP" label-for="zip" label-cols-md="4">
                            <b-form-input id="zip" type="text" :value=order.zip />
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-card>
        </b-col>
        
        <b-col md="4">
        </b-col>

        <b-col md="4">
            <actions />
        </b-col>

        <b-col md="8">
                <b-row>
                    <!-- <b-card  no-body class="order-product-content"> -->
                         <!-- <b-card-header>
                            <div>
                                <b-card-title>Cart</b-card-title>
                                <b-card-text class="font-small-3">
                                    Please be carefoul with editing products!
                                </b-card-text>
                            </div>
                        </b-card-header> -->
                    <div class="order-product-content">
                        <product-view :products="order.cart" />
                    </div>
                    <!-- </b-card> -->
                </b-row>
        </b-col>

    </b-row>
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <b-tab
      title="Order Logs"
      lazy
    >
      <b-alert
        variant="info"
        show
        class="mb-0"
      >
        <div class="alert-body">
          I'm lazy mounted!
        </div>
      </b-alert>
    </b-tab>


    <b-tab
      title="Client Orders"
      lazy
    >
      <b-alert
        variant="info"
        show
        class="mb-0"
      >
        <div class="alert-body">
          I'm lazy mounted!
        </div>
      </b-alert>
    </b-tab>

    <b-tab
      title="Payment Info"
      lazy
    >
      <b-alert
        variant="info"
        show
        class="mb-0"
      >
        <div class="alert-body">
          I'm lazy mounted!
        </div>
      </b-alert>
    </b-tab>


  </b-tabs>
</div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BTabs,
    BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProductView from '../components/Orders/OrderProductView'
import Actions from '../components/Orders/Actions'
import {
    mapGetters
} from 'vuex';

export default {
    directives: {
        Ripple,
    },
    components: {
        // BSV
        BCard,
        BCardBody,
        BBadge,
        BRow,
        BAvatar,
        BCol,
        BImg,
        BCardText,
        BLink,
        BButton,
        BDropdown,
        BDropdownItem,
        BTabs,
        BTab,
        BAlert,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        ProductView,
        Actions,
        BForm
    },

    computed: {
        order() {
            return this.$store.getters.orderView;
        }
    },

    mounted() {
        this.$store.dispatch("GET_ORDER", this.$route.params.id);
    }

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '@core/scss/vue/libs/swiper.scss';

@import '~swiper/css/swiper.css';
//  const { route } = useRouter()
//       const productId = route.value.params.id

.status {
    width: 200px;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
}
.order-product-content{
    width: 100%;
    position: relative;
    padding: 15px;
    //right: 15px;
}
</style>
