<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Actions</b-card-title>
        <b-card-text class="font-small-2">
          Chose your action for selected order
        </b-card-text>
      </div>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      
    <b-row class="ASDS">

        <b-col md="12">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-success"
              pill
            >
                Approve
            </b-button>
          </div>
        </b-col>

        <b-col md="12">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-primary"
              pill
            >
                Send To Courier
            </b-button>
          </div>
        </b-col>

        <b-col md="12">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-warning"
              pill
            >
              Report to administration
            </b-button>
          </div>
        </b-col>

          <b-col md="12">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-danger"
              pill
            >
                Ban client
            </b-button>
          </div>
        </b-col>

        <b-col md="12">
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-danger"
              pill
            >
              Archive Order
            </b-button>
          </div>
        </b-col>

    </b-row>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,BRow,BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'


/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BRow, BCol,
    VueApexCharts,
  },
  directives: {
        Ripple,
    },
  data() {
    return {
        test: null
    }
  },
}
</script>

<style lang='scss'>
.center-action-buttons{
    position: relative;
}
</style>
